import { axios } from '@/utils/request'

/**
 * 獲取用戶列表
 *
 * @author yubaoshan
 * @date 2020/4/26 12:08
 */
export function getUserPage (parameter) {
  return axios({
    url: '/sysUser/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 增加用戶
 *
 * @author yubaoshan
 * @date 2020/5/5 02:08
 */
export function sysUserAdd (parameter) {
  return axios({
    url: '/sysUser/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯用戶
 *
 * @author yubaoshan
 * @date 2020/5/5 02:08
 */
export function sysUserEdit (parameter) {
  return axios({
    url: '/sysUser/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 獲取用戶詳情
 *
 * @author yubaoshan
 * @date 2020/5/5 19:55
 */
export function sysUserDetail (parameter) {
  return axios({
    url: '/sysUser/detail',
    method: 'get',
    params: parameter
  })
}

/**
 * 刪除用戶
 *
 * @author yubaoshan
 * @date 2020/5/7 19:31
 */
export function sysUserDelete (parameter) {
  return axios({
    url: '/sysUser/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 導出用戶
 *
 * @author yubaoshan
 * @date 2021/5/30 00:23
 */
export function sysUserExport (parameter) {
  return axios({
    url: '/sysUser/export',
    method: 'get',
    params: parameter,
    responseType: 'blob'
  })
}

/**
 * 擁有角色
 *
 * @author yubaoshan
 * @date 2020/6/3 11:58
 */
export function sysUserOwnRole (parameter) {
  return axios({
    url: '/sysUser/ownRole',
    method: 'get',
    params: parameter
  })
}

/**
 * 授權角色
 *
 * @author yubaoshan
 * @date 2020/5/26 23:59
 */
export function sysUserGrantRole (parameter) {
  return axios({
    url: '/sysUser/grantRole',
    method: 'post',
    data: parameter
  })
}

/**
 * 擁有數據
 *
 * @author yubaoshan
 * @date 2020/6/2 23:14
 */
export function sysUserOwnData (parameter) {
  return axios({
    url: '/sysUser/ownData',
    method: 'get',
    params: parameter
  })
}

/**
 * 授權數據
 *
 * @author yubaoshan
 * @date 2020/6/2 23:15
 */
export function sysUserGrantData (parameter) {
  return axios({
    url: '/sysUser/grantData',
    method: 'post',
    data: parameter
  })
}

/**
 * 修改狀態
 *
 * @author yubaoshan
 * @date 2020/6/23 21:36
 */
export function sysUserChangeStatus (parameter) {
  return axios({
    url: '/sysUser/changeStatus',
    method: 'post',
    data: parameter
  })
}

/**
 * 重置密碼
 *
 * @author yubaoshan
 * @date 2020/6/23 22:04
 */
export function sysUserResetPwd (parameter) {
  return axios({
    url: '/sysUser/resetPwd',
    method: 'post',
    data: parameter
  })
}

/**
 * 修改密碼
 *
 * @author yubaoshan
 * @date 2020/6/25 00:25
 */
export function sysUserUpdatePwd (parameter) {
  return axios({
    url: '/sysUser/updatePwd',
    method: 'post',
    data: parameter
  })
}

/**
 * 用戶選擇器
 *
 * @author yubaoshan
 * @date 2020/6/25 00:25
 */
export function sysUserSelector (parameter) {
  return axios({
    url: '/sysUser/selector',
    method: 'get',
    params: parameter
  })
}

/**
 * 修改頭像
 *
 * @author yubaoshan
 * @date 2020/9/20 2:21
 */
export function sysUserUpdateAvatar (parameter) {
  return axios({
    url: '/sysUser/updateAvatar',
    method: 'post',
    data: parameter
  })
}

// 修改用戶簽名圖片
export function userUpdateSignature(data) {
  return axios({
    url: '/sys/userCenter/updateSignature',
    method: 'post',
    data
  })
}

/**
 * 更新基本信息
 *
 * @author yubaoshan
 * @date 2020/9/20 03:12
 */
export function sysUserUpdateInfo (parameter) {
  return axios({
    url: '/sysUser/updateInfo',
    method: 'post',
    data: parameter
  })
}

/**
 * 更新基本信息
 *
 * @author yubaoshan
 * @date 2020/9/20 03:12
 */
 export function sysPersonalUpdateInfo (data) {
  return axios({
    url: '/sysUser/personal/edit',
    method: 'post',
    data
  })
}